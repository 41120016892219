import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCurrentUserAction } from 'store/users'
import Header from 'ui/components/Header/'
import { ModalProjectPlan } from 'ui/components/ModalProjectPlan'
import ModalUserRole from 'ui/components/ModalUserRole'
import TaskForm from 'ui/modules/TaskDetail/components/TaskForm'
import TagModal from 'ui/modules/Users/components/TagModal'
import { Loader, Section, Wrapper } from './styled'

const Layout = ({ isAuthorized, children }) => {
  const dispatch = useDispatch()
  const [init, setInit] = useState(false)
  useEffect(() => {
    if (isAuthorized) {
      dispatch(getCurrentUserAction())
    }
    setTimeout(() => {
      setInit(true)
    }, 500)
  }, [isAuthorized])

  return (
    <>
      {!init && (
        <Loader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50px"
            height="50px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#0393FF"
              strokeWidth="6"
              r="35"
              strokeDasharray="164.93361431346415 56.97787143782138"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </Loader>
      )}
      {isAuthorized ? (
        <>
          <Header />
          <Section>{children}</Section>
        </>
      ) : (
        <Wrapper>{children}</Wrapper>
      )}
      <>
        <ModalUserRole />
        <TagModal />
        <TaskForm />
        <ModalProjectPlan />
      </>
    </>
  )
}

export default Layout
