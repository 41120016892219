export default function extractResponseError(response) {
  if (!response || typeof response != 'object') return null
  if (!response.status || (response.status >= 200 && response.status < 300))
    return null
  let data = response.data ?? response
  let error = data.errors
  if (error && typeof error == 'object') error = Object.values(error)[0]
  if (error && Array.isArray(error)) error = error[0]
  if (error) return error
  return (
    data.errors ??
    data.data?.error ??
    data.data?.message ??
    data.errorMessage ??
    data.error ??
    data.statusMessage ??
    'При выполнении запроса произошла ошибка'
  )
}
