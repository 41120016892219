import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getTaskDetail,
  getTaskWork,
  updateTask,
  deleteTask,
  createProjectTask,
  moveTaskToBacklog,
  releaseTask,
  toTestTask,
} from 'api/tasks/'
import { notifyError, notifySuccess } from 'ui/components/Notify'

export const getTaskDetailAction = createAsyncThunk(
  'tasks/getTaskDetail',
  async (id) => {
    return await getTaskDetail(id)
  }
)

export const getTaskWorkAction = createAsyncThunk(
  'tasks/getTaskWork',
  async (id) => {
    return await getTaskWork(id)
  }
)

export const updateTaskAction = createAsyncThunk(
  'tasks/updateTask',
  async (data) => {
    return await updateTask(data.id, data.values)
  }
)

export const deleteTaskAction = createAsyncThunk(
  'tasks/deleteTask',
  async (data) => {
    return await deleteTask(data.id)
  }
)

export const releaseTaskAction = createAsyncThunk(
  'tasks/releaseTask',
  async (data) => {
    return await releaseTask(data.id)
  }
)

export const sendToTestTaskAction = createAsyncThunk(
  'tasks/moveTaskToTest',
  async (id) => {
    return await toTestTask(id)
  }
)

export const createProjectTaskAction = createAsyncThunk(
  'tasks/createProjectTask',
  async (data) => {
    return await createProjectTask(data.slug, data.values)
  }
)

export const moveTaskToBacklogAction = createAsyncThunk(
  'tasks/moveTaskToBacklog',
  async (id) => {
    return await moveTaskToBacklog(id)
  }
)

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    getTaskStatus: 'idle',
    updateTaskStatus: 'idle',
    deleteTaskStatus: 'idle',
    releaseTaskStatus: 'idle',
    toTestTaskStatus: 'idle',
    updateTaskErrors: null,
    releaseTaskErrors: null,
    addProjectTaskStatus: 'idle',
    createdProjectTask: null,
    taskDetail: null,
    workStatus: 'idle',
    workList: [],
    createProjectTaskError: null,
    moveBacklogStatus: 'idle',
  },
  reducers: {
    clearWorkList: (state) => {
      state.workList = []
    },
    clearTaskDetail: (state) => {
      state.taskDetail = null
    },
    clearUpdateTaskStatus: (state) => {
      state.updateTaskStatus = 'idle'
    },
    clearReleaseTaskStatus: (state) => {
      state.releaseTaskStatus = 'idle'
    },
    clearToTestTaskStatus: (state) => {
      state.toTestTaskStatus = 'idle'
    },
    clearDeleteTaskStatus: (state) => {
      state.deleteTaskStatus = 'idle'
    },
    clearAddTaskStatus: (state) => {
      state.addProjectTaskStatus = 'idle'
    },
    clearMoveBacklogStatus: (state) => {
      state.moveBacklogStatus = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskDetailAction.pending, (state) => {
        state.getTaskStatus = 'pending'
      })
      .addCase(getTaskDetailAction.fulfilled, (state, action) => {
        state.taskDetail = action.payload.data || null
        state.getTaskStatus = 'idle'
      })
    builder
      .addCase(getTaskWorkAction.pending, (state) => {
        state.workStatus = 'pending'
      })
      .addCase(getTaskWorkAction.fulfilled, (state, action) => {
        state.workList = action.payload.data || null
        state.workStatus = 'idle'
      })
    builder
      .addCase(updateTaskAction.pending, (state) => {
        state.updateTaskStatus = 'pending'
      })
      .addCase(updateTaskAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.updateTaskStatus = 'error'
          state.updateTaskErrors = errors
          notifyError(
            action.payload?.data?.errors
              ? Object.values(errors).join(', ')
              : 'Вы не можете перенести эту задачу'
          )
        } else {
          state.updateTaskStatus = 'success'
        }
      })
    builder
      .addCase(releaseTaskAction.pending, (state) => {
        state.releaseTaskStatus = 'pending'
      })
      .addCase(releaseTaskAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.releaseTaskStatus = 'error'
          state.releaseTaskErrors = errors
          notifyError(
            action.payload?.data?.errors
              ? Object.values(errors).join(', ')
              : 'Вы не можете перенести эту задачу'
          )
        } else {
          state.releaseTaskStatus = 'success'
        }
      })
    builder
      .addCase(createProjectTaskAction.pending, (state) => {
        state.addProjectTaskStatus = 'pending'
      })
      .addCase(createProjectTaskAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.addProjectTaskStatus = 'error'
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message || ''
          )
        } else {
          state.addProjectTaskStatus = 'success'
          state.createdProjectTask = action.payload
        }
      })
    builder
      .addCase(deleteTaskAction.pending, (state) => {
        state.deleteTaskStatus = 'pending'
      })
      .addCase(deleteTaskAction.fulfilled, (state) => {
        state.deleteTaskStatus = 'success'
        notifySuccess('Задача удалена')
      })
    builder
      .addCase(sendToTestTaskAction.pending, (state) => {
        state.toTestTaskStatus = 'pending'
      })
      .addCase(sendToTestTaskAction.fulfilled, (state, action) => {
        if (!action.payload?.errorMessage) {
          state.toTestTaskStatus = 'success'
        } else {
          state.toTestTaskStatus = 'error'
          const errors = action.payload?.data?.errors || null
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message
          )
        }
      })
    builder
      .addCase(moveTaskToBacklogAction.pending, (state) => {
        state.moveBacklogStatus = 'pending'
      })
      .addCase(moveTaskToBacklogAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.moveBacklogStatus = 'error'
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message
          )
        } else {
          state.moveBacklogStatus = 'success'
        }
      })
  },
})

export const taskDetailSelector = (state) => state.tasks.taskDetail
export const taskWorkListSelector = (state) => state.tasks.workList
export const updateTaskStatusSelector = (state) => state.tasks.updateTaskStatus
export const updateTaskErrorsSelector = (state) => state.tasks.updateTaskErrors
export const releaseTaskErrorsSelector = (state) =>
  state.tasks.releaseTaskErrors
export const deleteTaskStatusSelector = (state) => state.tasks.deleteTaskStatus
export const releaseTaskStatusSelector = (state) =>
  state.tasks.releaseTaskStatus
export const toTestTaskStatusSelector = (state) => state.tasks.toTestTaskStatus
export const addProjectTaskStatusSelector = (state) =>
  state.tasks.addProjectTaskStatus
export const createdProjectTaskSelector = (state) =>
  state.tasks.createdProjectTask
export const moveBacklogStatusSelector = (state) =>
  state.tasks.moveBacklogStatus

export const {
  clearWorkList,
  clearUpdateTaskStatus,
  clearTaskDetail,
  clearAddTaskStatus,
  clearDeleteTaskStatus,
  clearMoveBacklogStatus,
  clearReleaseTaskStatus,
  clearToTestTaskStatus,
} = tasksSlice.actions

export default tasksSlice.reducer
