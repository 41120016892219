import { useFormikContext } from 'formik'

export function useFormikMediator(path) {
  const formik = useFormikContext()

  const getFullPath = (subPath) => {
    const hasSubPath = subPath && typeof subPath === 'string'
    const hasPath = path || hasSubPath
    if (!hasPath) {
      return undefined
    }
    return (
      (path ?? '') +
      (path && hasSubPath ? '.' : '') +
      (hasSubPath ? subPath : '')
    )
  }

  return {
    getMeta: (subPath) => formik.getFieldMeta(getFullPath(subPath)),
    getProps: (subPath) => formik.getFieldProps(getFullPath(subPath)),
    get: (subPath) => {
      const fullPath = getFullPath(subPath)
      if (fullPath == null) {
        return formik.values
      }
      return formik.getFieldMeta(fullPath).value
    },
    set: (subPathOrValue, value) => {
      const fullPath = getFullPath(subPathOrValue)
      if (subPathOrValue === undefined && value === undefined) {
        if (fullPath) {
          formik.setFieldValue(fullPath, undefined)
        } else {
          formik.reset()
        }
      } else if (value === undefined) {
        if (
          subPathOrValue &&
          typeof subPathOrValue === 'string' &&
          formik.getFieldMeta(fullPath).value !== undefined
        ) {
          formik.setFieldValue(fullPath, undefined)
        } else {
          if (
            subPathOrValue === '' &&
            typeof formik.getFieldMeta(fullPath).value !== 'string'
          ) {
            return
          }
          formik.setFieldValue(fullPath, subPathOrValue)
        }
      } else {
        formik.setFieldValue(fullPath, value)
      }
    },
  }
}
