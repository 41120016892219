import styled from 'styled-components'
import { Colors } from 'utils'
import { ButtonStyled } from '../buttons/Button/styled'
import { TableCell } from '../Table'

export const Wrapper = styled.div`
  & > *:not(:first-child) {
    margin-top: 16px;
  }
`

export const FilesWrapper = styled(Wrapper)``

export const ButtonWrapper = styled.div`
  ${ButtonStyled} {
    width: auto;
    max-width: 100%;
    padding: 0;
    color: ${Colors.Red.DRed};
    background-color: unset;
    height: auto;
  }
`

export const TableCellWide = styled(TableCell)`
  max-width: unset !important;
  overflow-wrap: anywhere;
  flex-grow: 1;
`

export const Errors = styled.div`
  color: ${Colors.Red.DRed};
`
export const Warning = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: ${Colors.Light.LinesGrey};
  color: ${Colors.Black.Black};
  display: flex;
  flex-direction: column;
  gap: 12px;
`
