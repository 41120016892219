import { useFormikContext } from 'formik'
import {
  AddEstimateButton,
  EstimateSwitch,
  TaskEstimatesContainer,
  TaskEstimateScrollview,
  TaskEstimatesHeader,
  TaskEstimatesRoot,
} from './styled'
import { TaskEstimate } from './components/TaskEstimate/TaskEstimate'
import { format } from 'date-fns'
import { InputLabel } from 'ui/components/forms/Input/styled'
import { randomId } from 'helpers/randomId'

export default function TaskEstimates({ executors, taskEditPrivileges }) {
  const formik = useFormikContext()
  const estimatesMeta = formik.getFieldMeta('estimates')
  const estimates = estimatesMeta.value ?? []
  const hasEstimates = estimates.length > 0
  const { canEditEstimate, canSetSupertask } = taskEditPrivileges
  const createEstimate = (userId) => {
    return {
      temp_id: randomId(),
      user_id: userId ?? null,
      estimate: '',
      date_start: format(new Date(), 'dd.MM.yyyy'),
      date_end: format(new Date(), 'dd.MM.yyyy'),
    }
  }
  const addEstimateHandler = () => {
    let newEstimates
    const selectedExecutors = formik.getFieldMeta('executors').value
    if (!hasEstimates && selectedExecutors.length > 0) {
      newEstimates = []
      selectedExecutors.forEach((selectedExecutor) =>
        newEstimates.push(createEstimate(selectedExecutor.id))
      )
    } else {
      newEstimates = [...estimates, createEstimate()]
    }
    formik.setFieldValue('estimates', newEstimates)
  }
  return (
    <TaskEstimatesRoot>
      {canEditEstimate && (
        <>
          {hasEstimates && (
            <InputLabel css="margin-bottom: 15px;">
              Оценки по ответственным
            </InputLabel>
          )}
          <TaskEstimatesHeader>
            <AddEstimateButton
              type="button"
              title="Добавить оценку"
              onClick={addEstimateHandler}
            />
            <div style={{ flexGrow: 1 }}></div>
            {canSetSupertask && (
              <EstimateSwitch
                label="Супер задача"
                checked={!!formik.getFieldMeta('is_supertask').value}
                onChange={(e) => {
                  formik.setFieldValue('is_overtime', 0)
                  formik.setFieldValue('is_supertask', Number(e.target.checked))
                }}
              />
            )}
            <EstimateSwitch
              label="Овертайм"
              checked={!!formik.getFieldMeta('is_overtime').value}
              onChange={(e) => {
                formik.setFieldValue('is_supertask', 0)
                formik.setFieldValue('is_overtime', Number(e.target.checked))
              }}
            />
          </TaskEstimatesHeader>
        </>
      )}

      {estimates.length > 0 && (
        <TaskEstimateScrollview className="task-estimate-scrollview">
          <TaskEstimatesContainer>
            <InputLabel>Ответственный</InputLabel>
            <InputLabel>Оценка</InputLabel>
            <InputLabel>Период выполнения</InputLabel>
            <span />
            {estimates.map((estimate, index) => (
              <TaskEstimate
                key={estimate.id ?? estimate.temp_id}
                executors={executors}
                index={index}
                taskEditPrivileges={taskEditPrivileges}
              />
            ))}
          </TaskEstimatesContainer>
        </TaskEstimateScrollview>
      )}
    </TaskEstimatesRoot>
  )
}
