import { forwardRef } from 'react'
import { ButtonStyled, ButtonLoader, ButtonTitle } from './styled'

const Button = forwardRef(
  (
    {
      theme = 'base',
      minWidth,
      title,
      onClick,
      icon,
      loading = '',
      attrTitle,
      ...props
    },
    ref
  ) => (
    <ButtonStyled
      theme={theme}
      minWidth={minWidth}
      onClick={onClick}
      loading={String(loading)}
      title={attrTitle}
      ref={ref}
      {...props}
    >
      {icon && icon}
      {loading && <ButtonLoader />}
      {title && <ButtonTitle>{title}</ButtonTitle>}
    </ButtonStyled>
  )
)

export default Button
