import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const openUserRoleModalAction = createAsyncThunk(
  'modals/openUserRoleModal',
  (data) => {
    return data
  }
)

export const openTaskModalAction = createAsyncThunk(
  'modals/openTaskModal',
  (data) => {
    return data
  }
)
export const openTagModalAction = createAsyncThunk(
  'modals/openTagModal',
  (data) => {
    return data
  }
)

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    userRole: null,
    projectPlan: null,
    TaskForm: {
      defaultType: null,
      defaultEpicBy: null,
      open: false,
      title: 'Редактирование задачи',
      defaultRelatedBy: null,
      eventType: null,
      defaultTaskDetails: null,
      stage: null,
    },
    tag: {
      open: false,
      title: 'Теги',
    },
  },
  reducers: {
    closeUserRoleModal: (state) => {
      state.userRole = null
    },
    closeTaskForm: (state) => {
      state.TaskForm = {
        open: false,
        defaultType: null,
        defaultEpicBy: null,
        defaultRelatedBy: null,
        updateRequest: null,
        defaultTaskDetails: null,
        title: null,
        eventType: null,
        openTask: null,
      }
    },
    closeTagModal: (state) => {
      state.tag = {
        open: false,
      }
    },
    openProjectPlanModal: (state, action) => {
      state.projectPlan = {
        open: true,
        projectSlug: action.payload.projectSlug,
      }
    },
    closeProjectPlanModal: (state) => {
      state.projectPlan = {
        open: false,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openUserRoleModalAction.fulfilled, (state, action) => {
      state.userRole = {
        open: true,
        title: 'Участвующие сотрудники на проекте',
        projectSlug: action.payload.projectSlug,
      }
    })
    builder.addCase(openTaskModalAction.fulfilled, (state, action) => {
      state.TaskForm = {
        open: true,
        defaultTaskDetails: action.payload.defaultTaskDetails,
        defaultType: action.payload.defaultType,
        defaultEpicBy: action.payload.defaultEpicBy,
        updateRequest: action.payload.updateRequest,
        openTask: action.payload.openTask,
        eventType: action.payload.eventType || 'create',
        defaultRelatedBy: action.payload.defaultRelatedBy,
        title: action.payload.title,
        stage: action.payload.stage,
      }
    })
    builder.addCase(openTagModalAction.fulfilled, (state) => {
      state.tag = {
        open: true,
      }
    })
  },
})

export const {
  closeUserRoleModal,
  closeTagModal,
  openProjectPlanModal,
  closeProjectPlanModal,
  closeTaskForm,
} = modalsSlice.actions

export const taskModalSelector = (state) => state.modals.TaskForm
export const userRoleModalSelector = (state) => state.modals.userRole
export const tagModalSelector = (state) => state.modals.tag
export const projectPlanModalSelector = (state) => state.modals.projectPlan

export default modalsSlice.reducer
