import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCalendar } from 'api/calendar'

export const loadCalendar = createAsyncThunk('calendar/load', async () => {
  return getCalendar()
})

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    isLoading: true,
    weekends: [],
    absences: {},
  },
  extraReducers: (builder) => {
    builder.addCase(loadCalendar.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(loadCalendar.fulfilled, (state, action) => {
      state.isLoading = false
      state.weekends = action.payload.data.weekends || []
      state.absences = action.payload.data.absences || {}
    })
  },
})

export default calendarSlice.reducer
