import { getDay } from 'date-fns'
import ru from 'date-fns/locale/ru'
import { registerLocale } from 'react-datepicker'
import Input from 'ui/components/forms/Input/'
import { DatePickerStyled } from './styled'

registerLocale('ru', ru)

const DatepickerInput = (props) => {
  const { disableWeekends, selected, onChange, onFocus, onKeyDown } = props

  const isWeekday = (date) => {
    if (disableWeekends) {
      const day = getDay(date)
      return day !== 0 && day !== 6
    }

    return true
  }

  return (
    <DatePickerStyled
      locale="ru"
      dateFormat="dd.MM.yyyy"
      selected={selected}
      onChange={onChange}
      filterDate={isWeekday}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          e.stopPropagation()
          return
        }
        onKeyDown?.(e)
      }}
      onFocus={(e) => {
        e.target.addEventListener(
          'blur',
          () =>
            setTimeout(() => {
              const popper = document.querySelector('.react-datepicker-popper')
              if (!popper?.contains(document.activeElement)) {
                e.target.closest('.react-datepicker-wrapper').dispatchEvent(
                  new MouseEvent('mousedown', {
                    bubbles: true,
                  })
                )
              }
            }),
          { once: true }
        )
        onFocus?.(e)
      }}
      customInput={<Input {...props} />}
      {...props}
    />
  )
}

export default DatepickerInput
