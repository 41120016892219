import * as Yup from 'yup'
import { checkIsFieldRequired } from 'helpers/checkIsFieldRequired'
import isValidURL from 'helpers/isValidURL'

export const validationSchema = ({
  requiredFields,
  visibleTaskFields,
  defaultTaskDetails,
  epicIsRequired,
  taskTypes,
}) =>
  Yup.object({
    name: visibleTaskFields?.includes('name')
      ? Yup.string()
          .max(200, 'Название не должно превышать 200 символов')
          .required('Нужно заполнить')
      : Yup.string(),
    task_type_id: visibleTaskFields?.includes('task_type')
      ? Yup.object().required('Нужно заполнить')
      : Yup.object(),
    estimate_worker: Yup.string().nullable(true) || Yup.number().nullable(true),
    estimate_cost: Yup.string().nullable(true) || Yup.number().nullable(true),
    executors:
      visibleTaskFields?.includes('users') &&
      defaultTaskDetails?.task_type?.id !== taskTypes.Epic
        ? Yup.array().min(1, 'Нужно заполнить')
        : Yup.array(),
    component_id:
      visibleTaskFields?.includes('component') &&
      checkIsFieldRequired('component_id', requiredFields)
        ? Yup.object().required('Нужно заполнить')
        : Yup.object(),
    priority_id: visibleTaskFields?.includes('priority')
      ? Yup.object().required('Нужно заполнить')
      : Yup.object(),
    description: Yup.string().test(
      'no-html-tags',
      'Нужно заполнить',
      function (value, context) {
        const cleanedValue = value?.replace(/<[^>]*>/g, '')
        if (cleanedValue?.trim() === '') {
          return context.createError({ message: 'Нужно заполнить' })
        } else {
          return true
        }
      }
    ),
    layout_link: visibleTaskFields?.includes('layout_link')
      ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
          return isValidURL(value)
        })
      : Yup.string(),
    markup_link: visibleTaskFields?.includes('markup_link')
      ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
          return isValidURL(value)
        })
      : Yup.string(),
    dev_link: visibleTaskFields?.includes('dev_link')
      ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
          return isValidURL(value)
        })
      : Yup.string(),
    //svyazi
    block: Yup.array(),
    related: Yup.array(),
    release_id: Yup.object(),
    epic_id: epicIsRequired
      ? Yup.object().required('Нужно заполнить')
      : Yup.object(),
    date_start: Yup.string().nullable(true).notRequired(),
    date_end: Yup.string().nullable(true).notRequired(),
    deadline: Yup.string().nullable(true).notRequired(),
    estimates: Yup.array().of(
      Yup.object({
        user_id: Yup.number().typeError('Нужно заполнить'),
        estimate: Yup.string().required('Нужно заполнить'),
        date_start: Yup.string().required('Нужно заполнить'),
        date_end: Yup.string().required('Нужно заполнить'),
      })
    ),
  })
