import { useFormikContext } from 'formik'
import Checkbox from 'ui/components/forms/Checkbox'
import { CheckboxesWrapper } from './styled'
import { usePrevilegies } from 'hooks/usePrevilegies'

export const ProjectPermUser = () => {
  const formik = useFormikContext()
  const { isAdmin } = usePrevilegies()

  return (
    <CheckboxesWrapper>
      <Checkbox
        label="Участники могут назначать задачи на себя"
        id="checkbox1"
        checked={formik.getFieldProps('perm_user_self_assign').value}
        {...formik.getFieldProps('perm_user_self_assign')}
      />
      <Checkbox
        label="Участники могут создавать задачи"
        id="checkbox2"
        checked={formik.getFieldProps('perm_user_create_task').value}
        {...formik.getFieldProps('perm_user_create_task')}
      />
      <Checkbox
        label="Менеджер считается админом"
        id="checkbox3"
        checked={formik.getFieldProps('perm_manager_is_admin').value}
        {...formik.getFieldProps('perm_manager_is_admin')}
      />
      <Checkbox
        label="Участники могут переводить задачи в &laquo;готово к тестированию&raquo;"
        id="checkbox4"
        checked={formik.getFieldProps('perm_user_to_rft').value}
        {...formik.getFieldProps('perm_user_to_rft')}
      />
      {isAdmin && (
        <Checkbox
          label="Менеджер может ставить &laquo;супер&nbsp;задачу&raquo;"
          id="checkbox5"
          checked={formik.getFieldProps('perm_manager_create_supertask').value}
          {...formik.getFieldProps('perm_manager_create_supertask')}
        />
      )}
      <Checkbox
        label="Эпик обязателен для задач"
        id="checkbox6"
        checked={formik.getFieldProps('perm_epic_required').value}
        {...formik.getFieldProps('perm_epic_required')}
      />
    </CheckboxesWrapper>
  )
}
