import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getDepartmentList } from 'api/departments'

export const getDepartmentListAction = createAsyncThunk(
  'departments/getDepertmentList',
  async () => {
    return getDepartmentList()
  }
)

const departmentsSlice = createSlice({
  name: 'departments',
  initialState: {
    getListStatus: 'idle',
    list: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentListAction.pending, (state) => {
        state.getListStatus = 'pending'
      })
      .addCase(getDepartmentListAction.fulfilled, (state, action) => {
        state.list = action.payload.data || []
        state.getListStatus = 'idle'
      })
  },
})

export const departmentListSelector = (state) => state.departments.list
export const departmentListStatusSelector = (state) =>
  state.departments.getListStatus

export default departmentsSlice.reducer
