import { FormikProvider, useFormik } from 'formik'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMedia } from 'react-use'
import { taskFileAttach } from 'api/tasks'
import { api } from 'apiv2'
import { formatToTime } from 'helpers/formatToTime'
import {
  prepareUserList,
  prepareCommonSelects,
  prepareTaskValues,
  prepareUserIdList,
  adaptTaskEstimates,
} from 'helpers/prepares'
import removeDuplicates from 'helpers/removeDuplicates'
import { usePrevilegies } from 'hooks/usePrevilegies'
import { TASK_TYPE } from 'store/constants'
import { taskTypesSelector, prioritiesSelector } from 'store/dictionaries'
import { closeTaskForm, taskModalSelector } from 'store/modals'
import {
  allTasksSelector,
  getAllTasksAction,
  projectInfoSelector,
} from 'store/projects'
import {
  updateTaskStatusSelector,
  createProjectTaskAction,
  updateTaskAction,
  addProjectTaskStatusSelector,
  clearAddTaskStatus,
  createdProjectTaskSelector,
  getTaskDetailAction,
} from 'store/tasks'
import { currentUserSelector } from 'store/users'
import { ScreenSize } from 'styles/constants'
import ConfirmModal from 'ui/components/ConfirmModal'
import ModalBasic from 'ui/components/ModalBasic'
import { notifyError, notifySuccess } from 'ui/components/Notify'
import Form from './components/Form'
// import TaskGradeItems from '../TaskGradeItems'
import { NotificationLink } from './styled'
import { retrieveProjectSlug } from 'helpers/retrieveProjectSlug'
import { validationSchema } from './validationSchema'

const TaskForm = ({}) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const projectSlug = retrieveProjectSlug(router)
  const isMobile = useMedia(ScreenSize.mobile)
  const [files, setFiles] = useState([])

  const {
    defaultType,
    open,
    title,
    defaultTaskDetails,
    eventType,
    openTask,
    stage,
    defaultRelatedBy,
  } = useSelector(taskModalSelector)

  const handlerClose = () => {
    setConfirmOpened(false)
    dispatch(closeTaskForm())
    clearAddTaskStatus()
  }
  const taskList = useSelector(allTasksSelector)
  const addProjectTaskStatus = useSelector(addProjectTaskStatusSelector)
  const createdProjectTask = useSelector(createdProjectTaskSelector)

  const priorities = useSelector(prioritiesSelector)
  const taskTypes = useSelector(taskTypesSelector)
  const projectInfo = useSelector(projectInfoSelector)
  const currentUser = useSelector(currentUserSelector)

  const taskStatus = useSelector(updateTaskStatusSelector)
  const { isManager, isAdmin } = usePrevilegies()
  const canEditEstimate = isManager || isAdmin
  const canSetSupertask =
    isAdmin || (isManager && projectInfo?.perm_manager_create_supertask)
  const taskEditPrivileges = { canSetSupertask, canEditEstimate }

  const epicIsRequired = !!projectInfo?.epicIsRequired

  const [visibleTaskFields, setVisibleTaskFields] = useState([])

  const [requiredFields, setRequiredFields] = useState({})
  const [pending, setPending] = useState(false)

  const preparedExecutorsList = prepareUserList(
    removeDuplicates(projectInfo?.users || [])
  )

  const executors = useMemo(() => {
    if (!projectInfo?.perm_user_self_assign && !isManager) {
      return preparedExecutorsList?.filter((i) => i.id !== currentUser?.id)
    }
    return preparedExecutorsList
  }, [preparedExecutorsList, isManager])

  useEffect(() => {
    if (taskStatus === 'error') {
      setPending(false)
    }
    if (taskStatus === 'success') {
      setPending(false)
      formik.resetForm()
      handlerClose()
    }
  }, [taskStatus])

  useEffect(() => {
    if (addProjectTaskStatus === 'success') {
      setPending(false)
      formik.resetForm()
      handlerClose()
      dispatch(api.util.invalidateTags(['KanbanTaskList']))
      notifySuccess(
        <a
          href={`${window.location.origin}/projects/${projectSlug}/${createdProjectTask.data.id}`}
          onClick={(e) => {
            openTask(e, createdProjectTask.data.id)
          }}
        >
          Задача
          <NotificationLink>
            {` #${createdProjectTask.data.id} `}
          </NotificationLink>
          успешно добавлена
          <div>
            <NotificationLink>Открыть</NotificationLink>
          </div>
        </a>
      )
    }
    if (addProjectTaskStatus === 'error') {
      setPending(false)
    }
  }, [addProjectTaskStatus])

  const startRelations = {
    block:
      defaultTaskDetails?.block?.length > 0
        ? prepareCommonSelects(
            taskList?.filter((i) =>
              defaultTaskDetails?.block?.map((i) => i.id).includes(i.id)
            )
          )
        : [],
    related:
      defaultTaskDetails?.related?.length > 0
        ? prepareCommonSelects(
            taskList?.filter((i) =>
              defaultTaskDetails?.related?.map((i) => i.id).includes(i.id)
            )
          )
        : [],
    release_id: defaultTaskDetails?.release?.id
      ? prepareCommonSelects(
          taskList?.filter((i) => i.id === defaultTaskDetails?.release.id)[0]
        )
      : '',
    epic_id: defaultTaskDetails?.epic?.id
      ? prepareCommonSelects(
          taskList?.find((i) => i.id === defaultTaskDetails?.epic.id)
        )
      : defaultTaskDetails?.task_type.id === TASK_TYPE.Epic
        ? prepareCommonSelects(
            taskList?.find((i) => i.id === defaultTaskDetails?.id)
          )
        : '',
  }

  const isAction = (...taskActionsToCheck) =>
    taskActionsToCheck.some((action) => action === eventType)
  const isNotAction = (...taskActionsToCheck) =>
    taskActionsToCheck.every((action) => action !== eventType)

  const newtaskReleated = taskList?.filter(
    (task) => task.id === defaultRelatedBy
  )

  const checkEvent = (value) => {
    if (
      isAction('copy', 'bug') ||
      defaultTaskDetails?.[value] == 0 ||
      !defaultTaskDetails?.[value]
    ) {
      return ''
    }

    const val = formatToTime(defaultTaskDetails?.[value]).replace(/\s/g, '')
    if (val == 0) return undefined
    return val
  }

  const createTask = async (
    preparedValues,
    executorsIdList,
    blockIds,
    relatedIds,
    typeId
  ) => {
    const resp = await dispatch(
      createProjectTaskAction({
        slug: projectInfo.slug,
        values:
          typeId === TASK_TYPE.Epic
            ? {
                ...preparedValues,
                executors: [],
                component_id: undefined,
                estimate_cost: undefined,
                estimate_worker: undefined,
                block: undefined,
                related: undefined,
                dev_link: undefined,
                markup_link: undefined,
                layout_link: undefined,
              }
            : {
                ...preparedValues,
                executors: executorsIdList || [],
                block: blockIds,
                related: relatedIds,
              },
      })
    ).unwrap()
    if (resp.data.id) {
      if (files?.length) {
        files?.forEach(async (file) => {
          try {
            await taskFileAttach(resp.data.id, file.id)
          } catch (error) {
            notifyError('Ошибка загрузки файлов')
          }
        })
      }
    }
  }

  const updateTask = (
    preparedValues,
    executorsIdList,
    blockIds,
    relatedIds,
    typeId,
    dates
  ) => {
    dispatch(
      updateTaskAction({
        id: defaultTaskDetails?.id,
        values:
          typeId === TASK_TYPE.Epic
            ? {
                ...preparedValues,
                epic_id: undefined,
                executors: [],
                components: [],
                block: null,
                related: null,
                ...dates,
              }
            : {
                ...preparedValues,
                block: blockIds,
                related: relatedIds,
                executors: executorsIdList || [],
                ...dates,
              },
      })
    )
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: isAction('create')
        ? undefined
        : defaultTaskDetails?.name || undefined,
      stage_id: isAction('edit') ? defaultTaskDetails?.stage?.id : '1',
      task_type_id: defaultType
        ? prepareCommonSelects(
            taskTypes?.find((i) => i.id === defaultType) || taskTypes[1]
          )
        : prepareCommonSelects(defaultTaskDetails?.task_type || taskTypes[1]),
      //estimate_worker: checkEvent('estimate_worker'),
      estimate_cost: checkEvent('estimate_cost'),
      executors: prepareUserList(defaultTaskDetails?.users) || undefined,
      component_id: defaultTaskDetails?.component
        ? prepareCommonSelects(defaultTaskDetails?.component)
        : undefined,
      priority_id: defaultTaskDetails?.priority
        ? prepareCommonSelects(defaultTaskDetails?.priority)
        : prepareCommonSelects(priorities[1]),
      layout_link: defaultTaskDetails?.layout_link
        ? defaultTaskDetails?.layout_link
        : undefined,
      markup_link: defaultTaskDetails?.markup_link
        ? defaultTaskDetails?.markup_link
        : undefined,
      dev_link: defaultTaskDetails?.dev_link
        ? defaultTaskDetails?.dev_link
        : undefined,
      description: isNotAction('edit', 'copy')
        ? ''
        : defaultTaskDetails?.description || '',
      //svyazi
      block: startRelations.block || undefined,
      related: defaultRelatedBy
        ? prepareCommonSelects(newtaskReleated)
        : startRelations.related || undefined,
      release_id: startRelations.release_id || undefined,
      epic_id: startRelations.epic_id || undefined,

      date_start: isAction('create', 'bug')
        ? undefined
        : defaultTaskDetails?.date_start || undefined,
      date_end: isAction('create', 'bug')
        ? undefined
        : defaultTaskDetails?.date_end || undefined,
      deadline: isNotAction('edit')
        ? undefined
        : defaultTaskDetails?.deadline || undefined,
      estimates: isAction('create', 'copy', 'bug')
        ? []
        : adaptTaskEstimates(defaultTaskDetails?.estimates ?? []),
      is_overtime: !canEditEstimate
        ? undefined
        : isAction('create', 'copy', 'bug')
          ? 0
          : (defaultTaskDetails?.is_overtime ?? 0),
      is_supertask: !canSetSupertask
        ? undefined
        : isAction('create', 'copy', 'bug')
          ? 0
          : (defaultTaskDetails?.is_supertask ?? 0),
    },
    validationSchema: validationSchema({
      requiredFields,
      visibleTaskFields,
      defaultTaskDetails,
      epicIsRequired,
      taskTypes,
    }),
    onSubmit: async (values) => {
      const preparedValues = prepareTaskValues(
        values,
        eventType,
        stage,
        !(isManager || isAdmin),
        canEditEstimate
      )
      const executorsIdList = prepareUserIdList(preparedValues.executors)
      const blockIds = values.block?.map((i) => i.id)
      const relatedIds = values.related?.map((i) => i.id)
      const typeId = values.task_type_id.id
      const dates = {
        date_start: values.date_start || undefined,
        date_end: values.date_end || undefined,
      }

      if (isAdmin || isManager) {
        Object.defineProperty(dates, 'deadline', {
          value: values.deadline,
        })
      }

      setPending(true)
      isAction('edit')
        ? updateTask(
            preparedValues,
            executorsIdList,
            blockIds,
            relatedIds,
            typeId,
            dates
          )
        : await createTask(
            preparedValues,
            executorsIdList,
            blockIds,
            relatedIds,
            typeId,
            dates
          )
    },
  })

  useEffect(() => {
    setVisibleTaskFields(
      projectInfo?.flow?.task_type_fields_map?.find(
        (i) =>
          i.task_type_id ===
          (formik?.values.task_type_id?.id >= 0
            ? formik.values.task_type_id.id
            : defaultTaskDetails?.task_type?.id)
      )?.fields
    )
  }, [projectInfo?.slug, formik?.values.task_type_id?.id])

  const lastOpenedTaskRef = useRef()

  useEffect(() => {
    if (open) {
      dispatch(getAllTasksAction(projectSlug))
      lastOpenedTaskRef.current = defaultTaskDetails
    } else {
      formik.resetForm()
      setFiles([])
      if (lastOpenedTaskRef.current?.id && formik.dirty) {
        dispatch(getTaskDetailAction(lastOpenedTaskRef.current?.id))
      }
      lastOpenedTaskRef.current = null
    }
  }, [open])

  const handleChangeTaskType = (taskTypeId) => {
    switch (taskTypeId) {
      // Эпик
      case TASK_TYPE.Epic:
        setRequiredFields({
          component_id: false,
          description: false,
        })
        break
      // Бэклог
      case TASK_TYPE.Backlog:
        setRequiredFields({
          component_id: false,
        })
        break
      default:
        setRequiredFields({})
        break
    }
  }

  const startDateHasValue = Boolean(formik.getFieldMeta('date_start').value)

  useEffect(() => {
    if (!startDateHasValue) {
      formik.setFieldValue('date_end', undefined)
    }
  }, [startDateHasValue])
  const [confirmOpened, setConfirmOpened] = useState(false)

  return (
    <ModalBasic
      size={isMobile ? 'fullscreen' : 'large'}
      title={title}
      open={open}
      handlerClose={() => setConfirmOpened(true)}
    >
      <FormikProvider value={formik}>
        <Form
          formik={formik}
          executors={executors}
          pending={pending}
          visibleTaskFields={visibleTaskFields}
          requiredFields={requiredFields}
          files={files}
          setFiles={setFiles}
          setConfirmOpened={setConfirmOpened}
          taskEditPrivileges={taskEditPrivileges}
        />
      </FormikProvider>
      <div>
        <ConfirmModal
          title="Закрыть окно?"
          open={confirmOpened}
          confirmHandler={handlerClose}
          handlerClose={() => setConfirmOpened(false)}
        />
      </div>
    </ModalBasic>
  )
}

export default TaskForm
