import makeRequest from 'api/'
import getFilterParams from 'helpers/getFilterParams'

export const getUserList = async (statuses) => {
  let params = statuses
    ? statuses.map((status, index) => `status[${index}]=${status}`).join('&')
    : null

  return await makeRequest({
    method: 'get',
    url: params ? `/user?${params}` : '/user',
  })
}

export const getCurrentUser = async () => {
  return await makeRequest({
    method: 'get',
    url: '/auth/user',
  })
}

export const getUserById = async (id) => {
  return await makeRequest({
    method: 'get',
    url: `/user/${id}`,
  })
}

export const deleteUser = async (id) => {
  return await makeRequest({
    method: 'delete',
    url: `/user/${id}`,
  })
}

export const updateUser = async (id, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/user/${id}`,
    data,
  })
}

export const createUser = async (data) => {
  return await makeRequest({
    method: 'post',
    url: '/user',
    data,
  })
}

export const getUserTasks = async (userId, data, controller) => {
  const params = {}
  let filterParams = {}
  if (data?.task) params['filter[name]'] = data.task
  if (data && Object.keys(data).length) {
    filterParams = getFilterParams(data, ['type_id', 'stage_id'])
  }

  return await makeRequest({
    method: 'get',
    url: `/user/${userId}/task${data.full_view ? `/legacy` : ``}`,
    signal: controller ? controller.signal : null,
    params: {
      ...params,
      ...filterParams,
    },
  })
}

export const updateUserTags = async (data) => {
  return await makeRequest({
    method: 'patch',
    url: `/user/${data.userId}/tags`,
    data: {
      tags: data.tags,
    },
  })
}

export const getManagers = async (id) => {
  return await makeRequest({
    method: 'get',
    url: `/user/managers/${id}`,
  })
}
