import { useState } from 'react'
import ControlButton from 'ui/components/ControlButton'
import Select from 'ui/components/forms/Select/'
import { notifyError } from 'ui/components/Notify'
import {
  AddButtonWrap,
  DeleteButtonWrap,
  SelectCol,
  SelectRow,
  SelectWrapper,
} from './styled'

export function SelectNewUser({
  userList,
  roleList,
  updateProjectUserMutation,
  updateUserList,
  slug,
}) {
  const [newUser, setNewUser] = useState(null)
  const addUser = () => {
    if (!newUser?.userId || !newUser?.roleId) {
      return notifyError('Введите имя пользователя или роль')
    }
    const roles = Array.isArray(newUser.roleId)
      ? newUser.roleId
      : [newUser.roleId]

    roles.forEach((role) =>
      updateProjectUserMutation({
        slug,
        userId: newUser?.userId,
        roleId: role,
      })
    )

    setNewUser(null)
  }

  return (
    <>
      {newUser && (
        <SelectRow>
          <SelectCol>
            <SelectWrapper>
              <Select
                label="Имя"
                id={`nameSelect`}
                options={userList}
                onChange={(value) => {
                  setNewUser((user) => ({
                    ...user,
                    userId: value.id,
                    ...value,
                  }))
                }}
                placeholder="Имя"
                maxHeight="200px"
                defaultValue={''}
              />
            </SelectWrapper>
          </SelectCol>
          <SelectCol>
            <Select
              placeholder="Роль"
              id={`roleSelect`}
              required
              options={roleList}
              onChange={(value) => {
                setNewUser((user) => ({
                  ...user,
                  roleId: value.id,
                }))
              }}
              isSearchable={false}
            />
          </SelectCol>
          <DeleteButtonWrap>
            <ControlButton theme="edit" onClick={addUser}>
              Подтвердить
            </ControlButton>
          </DeleteButtonWrap>
        </SelectRow>
      )}

      {!newUser && (
        <AddButtonWrap>
          <ControlButton
            theme="base"
            onClick={() => {
              setNewUser({})
              updateUserList()
            }}
          >
            + Добавить сотрудника
          </ControlButton>
        </AddButtonWrap>
      )}
    </>
  )
}
