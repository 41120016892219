import { Control } from './styled'

const ControlButton = ({
  theme = 'base',
  onClick,
  children,
  className,
  active,
}) => (
  <Control
    type="button"
    theme={theme}
    onClick={onClick}
    active={active}
    className={className}
  >
    {children}
  </Control>
)

export default ControlButton
