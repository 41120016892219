import { format, parse } from 'date-fns'
import { ru } from 'date-fns/locale'

export function castDate(date) {
  if (!date) {
    return undefined
  }
  if (date instanceof Date) {
    return date
  }
  if (typeof date === 'string') {
    if (date.match(/^\d\d\.\d\d\.\d\d\d\d$/)) {
      return parse(date, 'dd.MM.yyyy', new Date())
    }
    return new Date(date)
  }
}

export const formatDate = (date) => {
  if (date) {
    return format(castDate(date), 'd MMM yyyy', {
      locale: ru,
    })
  }
}

export const formatDateTime = (date) => {
  if (date) {
    return format(castDate(date), 'dd MMM yyyy H:mm', { locale: ru })
  }
}

export const formatDateByParseString = (date) => {
  const parsedDate = parse(date, 'd.M.yyyy', new Date())
  return format(parsedDate, 'd MMM yyyy', { locale: ru })
}

export const formatDateByTemplate = (date, template = 'd MMM yyyy HH:mm') => {
  if (date) {
    return format(castDate(date), template, { locale: ru })
  }
}
