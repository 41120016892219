import { api } from '../api'

export const gradesApi = api
  .enhanceEndpoints({
    addTagTypes: ['GetGrades', 'GetUserGrades', 'UsersGradesTasks'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getGradesList: builder.query({
        providesTags: ['projectType'],
        query: () => {
          return {
            url: `/grade-directions`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      getUserGrade: builder.query({
        query: (userId) => {
          return {
            url: `/user-grade/${userId}`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      getUserGradesTasks: builder.query({
        providesTags: ['UsersGradesTasks'],
        query: (userId) => {
          return {
            url: `/user-grade/list/${userId}`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
    }),
  })

export const {
  useGetGradesListQuery,
  useGetUserGradeQuery,
  useGetUserGradesTasksQuery,
} = gradesApi
