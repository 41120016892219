import { notifySuccess } from 'ui/components/Notify'
import { prepareTaskEstimates } from 'helpers/prepares'
import { taskModalSelector } from 'store/modals'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { safeQuery } from 'helpers/safeQuery'
import {
  createTaskEstimate,
  deleteTaskEstimate,
  updateTaskEstimate,
} from 'api/tasks'
import { useFormikMediator } from 'hooks/useFormikMediator'
import { useEffect, useRef } from 'react'

const NEWTASK_EVENTS = ['create', 'copy', 'bug']

const estimateValidationShema = Yup.object({
  user_id: Yup.number().typeError(),
  estimate: Yup.string().required(),
  date_start: Yup.string().required(),
  date_end: Yup.string().required(),
})

export function useEstimateAutosaving(index) {
  const form = useFormikMediator()
  const formEstimate = useFormikMediator(`estimates[${index}]`)

  const { eventType, defaultTaskDetails: { id: taskId } = {} } =
    useSelector(taskModalSelector)
  const isNewTask = NEWTASK_EVENTS.includes(eventType)
  const estimate = formEstimate.get()
  const estimates = form.get('estimates')

  const estimateHasChangedRef = useRef(false)
  const lastEstimateValueRef = useRef(formEstimate.get('estimate'))
  const lastValidEstimateRef = useRef({ ...estimate })

  const taskExecutors = form.get(`executors`)
  const estimateExecutorId = formEstimate.get(`user_id`)

  useEffect(() => {
    // если ответственный текущей оценки был удален, удаляем и оценку
    const isRemovedEstimate =
      estimateExecutorId &&
      taskExecutors.every((executor) => executor.id != estimateExecutorId)
    if (isRemovedEstimate) {
      ;(async () => {
        let deleted = true
        if (estimate.id) {
          deleted = await safeQuery(() => deleteTaskEstimate(estimate.id))
        }
        if (deleted) {
          form.set(
            'estimates',
            estimates.filter(({ user_id }) => user_id != estimateExecutorId)
          )
        }
      })()
      return
    }
    if (!estimateHasChangedRef.current || isNewTask) {
      return
    }
    estimateHasChangedRef.current = false

    estimateValidationShema
      .validate(estimate)
      .then((validEstimate) => {
        ;(async () => {
          if (estimate.id) {
            let savedEstimate = await safeQuery(() =>
              updateTaskEstimate(
                estimate.id,
                prepareTaskEstimates(validEstimate)
              )
            )
            if (savedEstimate?.data) {
              savedEstimate = savedEstimate.data
            }
            if (savedEstimate || savedEstimate === '') {
              lastValidEstimateRef.current = { ...validEstimate }
              notifySuccess('Оценка успешно изменена')
            } else {
              formEstimate.set(lastValidEstimateRef.current)
              lastEstimateValueRef.current =
                lastValidEstimateRef.current.estimate
            }
          } else {
            let newEstimate = await safeQuery(() =>
              createTaskEstimate(taskId, prepareTaskEstimates(validEstimate))
            )
            if (newEstimate?.data) {
              newEstimate = newEstimate.data
            }
            if (newEstimate) {
              newEstimate = { ...validEstimate, id: newEstimate.id }
              formEstimate.set(newEstimate)
              lastValidEstimateRef.current = { ...validEstimate }
              notifySuccess('Оценка успешно добавлена')
            } else {
              formEstimate.set(lastValidEstimateRef.current)
              lastEstimateValueRef.current =
                lastValidEstimateRef.current.estimate
            }
          }
        })()
      })
      .catch(() => {})
  }, [estimateHasChangedRef.current])
  return {
    markEstimateChanged() {
      estimateHasChangedRef.current = true
    },
    getLastEstimateValue() {
      return lastEstimateValueRef.current
    },
    setLastEstimateValue(newEstimateValue) {
      lastEstimateValueRef.current = newEstimateValue
    },
    resetLastValidEstimate(newEstimate) {
      Object.assign(lastValidEstimateRef.current, newEstimate)
    },
  }
}
