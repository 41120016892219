import classNames from 'classnames'
import Select, { components } from 'react-select'
import Icon from 'ui/components/Icon'
import Scrollbar from 'ui/components/Scrollbar'
import { Colors, Transition } from 'utils/constants'
import { OptionText, SelectWrap, SelectLabel, SelectError } from './styled'
import { forwardRef } from 'react'

const CLASSNAME_PREFIX = 'Select'

const customStyles = {
  option: (styles, state) => {
    return {
      ...styles,
      cursor: 'pointer',
      backgroundColor: state.isFocused
        ? `${Colors.Light.CardsBack}`
        : 'transparent',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      paddingLeft: '16px',
      color: `${Colors.Black.BlocksBackground}`,
      outline: 'none !important',
    }
  },
  control: (styles, state) => ({
    ...styles,
    borderColor: `${Colors.Light.CardsBack}`,
    backgroundColor: `${Colors.Light.CardsBack}`,
    transition: `all ${Transition.ms300}`,
    height: '42px',
    minHeight: '42px',
    cursor: 'pointer',
    outline: 'none !important',
    boxShadow: 'none !important',
    borderColor: state.isFocused
      ? `${Colors.DS.MainBlue}`
      : `${Colors.Light.CardsBack}`,
    borderRadius: '8px',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: `${Colors.Black.TextSec}`,
    fontWeight: 400,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '0px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: `${Colors.Black.TextSec}`,
    paddingLeft: '16px',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '8px',
    boxShadow:
      '0px 2px 2px -1px rgba(27, 35, 44, 0.04), 0px 8px 16px -2px rgba(27, 36, 44, 0.12)',
    border: `1px solid ${Colors.Light.LinesGrey}`,
    borderRadius: '6px',
    zIndex: 10,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '0 10px 0 0',
  }),
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="down" size="24" />
    </components.DropdownIndicator>
  )
}

const Menu = ({ children, selectProps: { maxHeight }, ...props }) => {
  return (
    <components.Menu {...props}>
      <Scrollbar maxHeight={maxHeight}>{children}</Scrollbar>
    </components.Menu>
  )
}

const Option = ({ value, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionText title={value}>{value}</OptionText>
    </components.Option>
  )
}

const CommonSelect = forwardRef(
  (
    { label, meta, error, required, type, className, noMargin, ...props },
    ref
  ) => {
    const isTouched = meta && meta.touched
    const hasError = isTouched && meta.error
    return (
      <SelectWrap
        $noMargin={noMargin}
        ref={ref}
        type={type}
        className={classNames(
          {
            'has-label': label,
            'has-error': hasError,
          },
          className
        )}
      >
        {label && (
          <SelectLabel type={type}>
            {label} {required && <span>*</span>}
          </SelectLabel>
        )}
        <Select
          isClearable={false}
          styles={customStyles}
          captureMenuScroll={false}
          classNamePrefix={CLASSNAME_PREFIX}
          noOptionsMessage={() => 'Не найдено'}
          components={{
            Option,
            DropdownIndicator,
            Menu,
          }}
          placeholder="Не выбран"
          {...props}
        />
        {hasError && <SelectError>{error || meta.error}</SelectError>}
      </SelectWrap>
    )
  }
)

export default CommonSelect
