import { useState } from 'react'
import ConfirmModal from 'ui/components/ConfirmModal/'
import ControlButton from 'ui/components/ControlButton'
import Select from 'ui/components/forms/Select/'
import { notifyError } from 'ui/components/Notify'
import { DeleteButtonWrap, SelectCol, SelectRow, SelectWrapper } from './styled'

export function SelectUser({
  users,
  userList,
  roleList,
  updateProjectUserMutation,
  deleteProjectUserMutation,
  slug,
}) {
  const [openModal, setModalOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState({})

  const deleteHandler = () => {
    deleteProjectUserMutation({
      slug,
      userId: currentUser?.id,
      roleId: currentUser?.role[0].id,
    })
    setModalOpen(false)
    setCurrentUser(null)
  }

  const setUserToDelete = (user) => {
    setCurrentUser(user)
    setModalOpen(true)
  }

  const roleChange = (value, user) => {
    const currentRole = roleList.find((role) => value.id === role.id)
    if (currentRole) {
      updateProjectUserMutation({
        slug,
        userId: user.id,
        roleId: currentRole.id,
      })
    } else {
      notifyError('Не обнаружена роль')
    }
  }

  return (
    <>
      {users?.map((user, index) => {
        return (
          <SelectRow key={user.id}>
            <SelectCol>
              <SelectWrapper>
                <Select
                  label="Имя"
                  id={`nameSelect${index}`}
                  options={userList}
                  placeholder="Имя"
                  maxHeight="200px"
                  defaultValue={{
                    ...user,
                    label: `${user.name ? `${user.surname} ${user.name}` : ''}`,
                  }}
                  isDisabled={true}
                />
              </SelectWrapper>
            </SelectCol>
            <SelectCol>
              <Select
                placeholder="Роль"
                label="Роль"
                id={`roleSelect${index}`}
                required
                options={roleList}
                onChange={(value) => roleChange(value, user)}
                defaultValue={user.role?.map((i) => {
                  return {
                    id: i.id,
                    value: i.label ? i.label : i.name,
                    label: i.label ? i.label : i.name,
                  }
                })}
              />
            </SelectCol>
            <DeleteButtonWrap>
              <ControlButton theme="edit" onClick={() => setUserToDelete(user)}>
                Удалить
              </ControlButton>
            </DeleteButtonWrap>
          </SelectRow>
        )
      })}
      <ConfirmModal
        title={`Удалить пользователя ${currentUser?.name + ' ' + currentUser?.surname} из проекта?`}
        open={openModal}
        handlerClose={() => setModalOpen(false)}
        confirmHandler={deleteHandler}
      />
    </>
  )
}
