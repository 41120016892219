import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from 'apiv2/api'
import backlogListReducer from 'store/backlogList'
import { dictionariesReducer } from 'store/dictionaries'
import { estimatesReducer } from 'store/estimates'
import gradesReducer from 'store/grades'
import modalsReducer from 'store/modals'
import projectsReducer from 'store/projects/'
import reportsReducer from 'store/reports/'
import rolesReducer from 'store/roles/'
import tasksReducer from 'store/tasks/'
import tasksListReducer from 'store/tasksList'
import usersReducer from 'store/users/'
import weeklyPlanReducer from 'store/weeklyPlan'
import managersReducer from 'store/managers'
import calendarReducer from 'store/calendar'
import departmentsReducer from 'store/departments'

const devMode = process.env.NODE_ENV === 'development'

const store = configureStore({
  reducer: {
    users: usersReducer,
    projects: projectsReducer,
    roles: rolesReducer,
    tasks: tasksReducer,
    dictionaries: dictionariesReducer,
    reports: reportsReducer,
    modals: modalsReducer,
    tasksList: tasksListReducer,
    backlogList: backlogListReducer,
    estimates: estimatesReducer,
    grades: gradesReducer,
    weeklyPlan: weeklyPlanReducer,
    managers: managersReducer,
    calendar: calendarReducer,
    departments: departmentsReducer,
    [api.reducerPath]: api.reducer,
  },
  devMode: devMode,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([api.middleware]),
})

setupListeners(store.dispatch)

export default store
