import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getProjectInfo,
  getProjectList,
  createProject,
  updateProject,
  updateProjectUser,
  deleteProjectUser,
  getTaskList,
  getAllTasks,
  getProjectUsers,
} from 'api/projects/'
import { notifyError } from 'ui/components/Notify'

export const getProjectListAction = createAsyncThunk(
  'projects/getProjectList',
  async (flag) => {
    return await getProjectList(flag)
  }
)

export const createProjectAction = createAsyncThunk(
  'projects/createProject',
  async (data) => {
    return await createProject(data)
  }
)

export const updateProjectAction = createAsyncThunk(
  'projects/updateProject',
  async (data) => {
    return await updateProject(data.id, data.values)
  }
)

export const updateProjectUserAction = createAsyncThunk(
  'projects/updateProjectUser',
  async (data) => {
    return await updateProjectUser(data.slug, data.userId, data.roleId)
  }
)

export const deleteProjectUserAction = createAsyncThunk(
  'projects/deleteProjectUser',
  async (data) => {
    return await deleteProjectUser(data.slug, data.userId, data.roleId)
  }
)

export const getProjectInfoAction = createAsyncThunk(
  'projects/getProjectInfo',
  async (slug) => {
    return await getProjectInfo(slug)
  }
)
export const getProjectUsersAction = createAsyncThunk(
  'projects/getProjectUsers',
  async (slug) => {
    return await getProjectUsers(slug)
  }
)
export const getTaskListAction = createAsyncThunk(
  'projects/getTaskList',
  async (data) => {
    if (typeof data === 'string') {
      return await getTaskList(data, data, data.controller)
    } else return await getTaskList(data.slug, data, data.controller)
  }
)
export const getAllTasksAction = createAsyncThunk(
  'project/getAllTasks',
  async (slug) => {
    return await getAllTasks(slug)
  }
)

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    getListStatus: 'idle',
    createStatus: 'idle',
    updateStatus: 'idle',
    updateUserStatus: 'idle',
    deleteUserStatus: 'idle',
    getInfoStatus: 'idle',
    list: [],
    taskList: [],
    sortedTask: [],
    allTasks: [],
    getTaskStatus: 'idle',
    getAllTasksStatus: 'idle',
    projectInfo: null,
    createProjectError: null,
  },
  reducers: {
    clearCreateStatus: (state) => {
      state.createStatus = 'idle'
    },
    clearProjectInfo: (state) => {
      state.projectInfo = null
    },
    clearTaskList: (state) => {
      if (!state.projectInfo) {
        state.taskList = []
        state.sortedTask = []
      }
      state.allTasks = []
    },
    clearUpdateStatus: (state) => {
      state.updateStatus = 'idle'
    },
    clearProjectList: (state) => {
      state.list = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectListAction.pending, (state) => {
        state.getListStatus = 'pending'
      })
      .addCase(getProjectListAction.fulfilled, (state, action) => {
        state.list = action.payload || []
        state.getListStatus = 'idle'
      })
    builder
      .addCase(createProjectAction.pending, (state) => {
        state.createStatus = 'pending'
      })
      .addCase(createProjectAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.createStatus = 'error'
          state.createProjectError = errors
          notifyError(Object.values(errors).join(', '))
        } else {
          state.createStatus = 'success'
        }
      })
    builder
      .addCase(updateProjectAction.pending, (state) => {
        state.updateStatus = 'pending'
      })
      .addCase(updateProjectAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.updateStatus = 'error'
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message
          )
        } else {
          state.updateStatus = 'success'
        }
      })
    builder
      .addCase(getTaskListAction.pending, (state) => {
        state.getTaskStatus = 'pending'
      })
      .addCase(getTaskListAction.fulfilled, (state, action) => {
        state.getTaskStatus = 'success'
        state.allTasks = action.payload?.allTasks.data || []
        state.taskList = action.payload?.task.data || []
        state.sortedTask = action.payload?.sortedTask || []
      })
      .addCase(getTaskListAction.rejected, (state) => {
        state.getTaskStatus = 'error'
      })
      .addCase(getAllTasksAction.pending, (state) => {
        state.getAllTasksStatus = 'pending'
      })
      .addCase(getAllTasksAction.rejected, (state) => {
        state.getAllTasksStatus = 'error'
      })
      .addCase(getAllTasksAction.fulfilled, (state, action) => {
        state.allTasks = action.payload.data || []
        state.getAllTasksStatus = 'success'
      })
    builder
      .addCase(getProjectInfoAction.pending, (state) => {
        state.getInfoStatus = 'pending'
      })
      .addCase(getProjectInfoAction.fulfilled, (state, action) => {
        state.projectInfo = action.payload.data || null
        state.getInfoStatus = 'success'
      })
    builder
      .addCase(getProjectUsersAction.pending, (state) => {
        state.getProjectUsersStatus = 'pending'
      })
      .addCase(getProjectUsersAction.fulfilled, (state, action) => {
        state.projectUsers = action.payload.data || null
        state.getProjectUsersStatus = 'success'
      })
    builder
      .addCase(updateProjectUserAction.pending, (state) => {
        state.updateUserStatus = 'pending'
      })
      .addCase(updateProjectUserAction.fulfilled, (state) => {
        state.updateUserStatus = 'success'
      })
    builder
      .addCase(deleteProjectUserAction.pending, (state) => {
        state.deleteUserStatus = 'pending'
      })
      .addCase(deleteProjectUserAction.fulfilled, (state) => {
        state.deleteUserStatus = 'success'
      })
  },
})

export const projectListSelector = (state) => state.projects.list
export const projectInfoStatusSelector = (state) => state.projects.getInfoStatus
export const projectInfoIsLoadedSelector = (state) =>
  projectInfoStatusSelector(state) == 'success'
export const projectInfoSelector = (state) => state.projects.projectInfo
export const createProjectStatusSelector = (state) =>
  state.projects.createStatus
export const updateProjectStatusSelector = (state) =>
  state.projects.updateStatus
export const createProjectErrorSelector = (state) =>
  state.projects.createProjectError
export const taskListSelector = (state) => state.projects.taskList
export const sortedTaskSelector = (state) => state.projects.sortedTask
export const taskStatusSelector = (state) => state.projects.getTaskStatus
export const allTasksSelector = (state) => state.projects.allTasks
export const projectListStatusSelector = (state) => state.projects.getListStatus
export const projectUsersSelector = (state) => state.projects.projectUsers
export const getProjectUsersStatusSelector = (state) =>
  state.projects.getProjectUsersStatus
export const projectUsersAreLoadedSelector = (state) =>
  getProjectUsersStatusSelector(state) == 'success'
export const getAllTasksStatusSelector = (state) =>
  state.projects.getAllTasksStatus
export const allTasksAreLoadedSelector = (state) =>
  getAllTasksStatusSelector(state) == 'success'
export const {
  clearCreateStatus,
  clearUpdateStatus,
  clearProjectInfo,
  clearTaskList,
  clearProjectList,
} = projectsSlice.actions

export default projectsSlice.reducer
