import { api } from '../api'

export const projectsApi = api
  .enhanceEndpoints({
    addTagTypes: ['projectInfo', 'projectUsers'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProjectInfo: builder.query({
        providesTags: ['projectInfo'],
        query: (slug) => {
          return {
            url: `/project/${slug}`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      updateProjectUsers: builder.query({
        providesTags: ['projectUsers'],
        query: (slug) => {
          return {
            url: `/project/${slug}/user`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      updateProjectUser: builder.mutation({
        keepUnusedDataFor: 5,
        query: ({ slug, userId, roleId }) => {
          return {
            url: `/project/${slug}/user/${userId}`,
            method: 'PATCH',
            body: { role_id: roleId },
          }
        },
        invalidatesTags: ['projectInfo'],
      }),
      deleteProjectUser: builder.mutation({
        keepUnusedDataFor: 5,
        query: ({ slug, userId, roleId }) => {
          return {
            url: `/project/${slug}/user/${userId}`,
            method: 'DELETE',
            body: { role_id: roleId },
          }
        },
        invalidatesTags: ['projectInfo'],
      }),
      updateProject: builder.mutation({
        keepUnusedDataFor: 5,
        query: ({ slug, data }) => {
          return {
            url: `/project/${slug}`,
            method: 'PATCH',
            body: data,
          }
        },
        invalidatesTags: ['projectInfo'],
      }),
      getProjectPlans: builder.query({
        providesTags: ['projectPlans'],
        query: (slug) => {
          return {
            url: `/project/${slug}/file`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      crudProjectPlans: builder.mutation({
        query: ({ projectSlug, file, method = 'PATCH' }) => {
          return {
            url: `/project/${projectSlug}/file/${file}`,
            method: method,
          }
        },
        transformResponse: (response) => response?.data || [],
        invalidatesTags: ['projectPlans'],
      }),
    }),
  })

export const {
  useUpdateProjectUserMutation,
  useGetProjectInfoQuery,
  useUpdateProjectMutation,
  useUpdateProjectUsersQuery,
  useDeleteProjectUserMutation,
  useGetProjectPlansQuery,
  useCrudProjectPlansMutation,
} = projectsApi
