import Link from 'next/link'
import { Card, Info, Name, Position, AdditionalText } from './styled'
import { path } from 'utils/constants/path'
import { AvatarSpecial } from '../AvatarSpecial'

const UserCardSmall = ({
  mode = 'default', // : 'modal' | 'default'
  as, //if mode default
  href = null, // if mode default
  handler, //if mode modal
  direction = 'horizontal', // 'horizontal' || 'vertical'
  name = '',
  avatar,
  position = '',
  additionalText = '',
  style,
}) => {
  const onClickLink = () => {
    if (typeof handler === 'function') {
      handler()
    }
  }
  const { defaultAvatarPath } = path
  return (
    <Card style={style} direction={direction}>
      {mode === 'default' && (
        <Link href={href} as={as}>
          <a onClick={onClickLink} target="_blank">
            <AvatarSpecial src={avatar} alt={name} />
            <Info>
              <Name>{name}</Name>
              {position && <Position>{position}</Position>}
              {additionalText && (
                <AdditionalText>
                  {Array.isArray(additionalText)
                    ? additionalText.join(', ')
                    : additionalText}
                </AdditionalText>
              )}
            </Info>
          </a>
        </Link>
      )}
      {mode === 'modal' && (
        <a onClick={onClickLink}>
          <AvatarSpecial src={avatar} alt={name} />
          <Info>
            <Name>{name}</Name>
            {position && <Position>{position}</Position>}
            {additionalText && (
              <AdditionalText>
                {additionalText.split(' ').join(', ')}
              </AdditionalText>
            )}
          </Info>
        </a>
      )}
    </Card>
  )
}

export default UserCardSmall
