import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFile } from 'api/file'
import { useCrudProjectPlansMutation, useGetProjectPlansQuery } from 'apiv2'
import { projectPlanModalSelector, closeProjectPlanModal } from 'store/modals'

import { formatDateByTemplate } from 'helpers/formatDate'
import FileUploader from 'ui/components/forms/FileUploader'
import Icon from 'ui/components/Icon'
import ModalBasic from 'ui/components/ModalBasic'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'ui/components/Table'
import {

  FilesWrapper,
  TableCellWide,
  Warning,
  Errors,
  Wrapper,
} from './styled'

export const ModalProjectPlan = () => {
  const modalInfo = useSelector(projectPlanModalSelector)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const { data } = useGetProjectPlansQuery(modalInfo?.projectSlug, {
    skip: !modalInfo?.projectSlug,
  })
  const [crudProjectPlan] = useCrudProjectPlansMutation()
  const handlerClose = () => {
    dispatch(closeProjectPlanModal())
  }
  const sendFileHandler = async (fileList) => {
    if (fileList.every((file) => file.type === 'text/xml')) {
      const fd = new FormData()

      fileList.forEach((file) => {
        fd.append('file[]', file)
      })
      try {
        const { data } = await uploadFile(fd)
        if (data && Array.isArray(data)) {
          const promiseArr = data.map((file) =>
            crudProjectPlan({
              projectSlug: modalInfo?.projectSlug,
              file: file.id,
            })
          )
          await Promise.all(promiseArr)
          setError(null)
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      const errorFiles = fileList
        .filter((file) => file.type !== 'text/xml')
        .map((file) => file.name)
        .join(', ')
      setError(`Неверный формат файлов: ${errorFiles}.
      Повторите загрузку с файлами формата xml.
      `)
    }
  }

  const getFullName = (item) => `${item.surname} ${item.name}`

  useEffect(() => {
    if (!modalInfo?.open) {
      setError(null)
    }
  }, [modalInfo?.open])
  return (
    <ModalBasic
      size="medium"
      open={modalInfo?.open}
      handlerClose={handlerClose}
      title={'План-график проекта'}
    >
      <Wrapper>
        <FilesWrapper>
          {!!data?.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellWide>Ссылка</TableCellWide>
                  <TableCell >Автор</TableCell>
                  <TableCell >Измененно</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((el) => (
                  <TableRow key={el?.id}>
                    <TableCellWide>
                      <a href={`/msp?url=${el?.link}`} key={el?.id}>
                        {el?.original_name}
                      </a>
                    </TableCellWide>
                    <TableCell >{el?.user && getFullName(el?.user)} </TableCell>
                    <TableCell >{el?.updated_at && formatDateByTemplate(new Date(el?.updated_at))} </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </FilesWrapper>
        <Warning>
          <Icon name="error" />
          <p>
            Столбец Длительность не должен содержать нулевое, или неточное
            значение. Задачи/вехи с длительность в виде <strong>58</strong>?
            должны быть приведены к корректному значению <strong>58</strong>
          </p>
        </Warning>
        <FileUploader
          id="descriptionFile"
          items={[]}
          showUploader
          sendFileHandler={sendFileHandler}
        />
        {error && <Errors>{error}</Errors>}
      </Wrapper>
    </ModalBasic>
  )
}
