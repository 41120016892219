export const formatEstimateTime = (value) => {
  if (
    /^((\d+)|(\d+\s*[чм])|(\d+\s*[hm])|(\d+\s*ч\s*\d+\s*м)|(\d+\s*h\s*\d+\s*m)|(\d+[.,]\d+))$/.test(
      value
    )
  ) {
    if (value.match(/\d+[.,]\d+/)) {
      const arr = value.split(/[.,]/)
      return `${arr[0]}ч ${Math.round((arr[1] / 10 ** arr[1].toString()?.length) * 60)}м`
    }
    if (value.match(/^\d+$/)) {
      return value + 'ч'
    } else {
      return value
    }
  }

  return ''
}
