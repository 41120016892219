import { endOfDay, format, parse } from 'date-fns'
import { useFormikContext } from 'formik'
import { DateFieldContainer } from '../styled'
import DatepickerInput from 'ui/components/forms/DatepickerInput'
import { useFormikMediator } from 'hooks/useFormikMediator'

export function TaskEstimatePeriod({ estimateIndex, autosaveContext }) {
  const formik = useFormikContext()
  const formEstimate = useFormikMediator(`estimates[${estimateIndex}]`)
  const formatDate = (date) => (date ? format(date, 'dd.MM.yyyy') : null)
  const setEstimateDate = (field, date) =>
    formEstimate.set(field, formatDate(date))
  const estimate = formEstimate.get()

  return (
    <DateFieldContainer>
      <DatepickerInput
        autoComplete="off"
        startDate={
          estimate.date_start
            ? parse(estimate.date_start, 'dd.MM.yyyy', new Date())
            : undefined
        }
        endDate={
          estimate.date_end
            ? parse(estimate.date_end, 'dd.MM.yyyy', new Date())
            : undefined
        }
        selectsRange
        monthsShown={2}
        placeholderText="Период выполнения"
        meta={formEstimate.getMeta('date_end')}
        onChange={([from, to]) => {
          if (!to) {
            formEstimate.set('date_end', '')
          } else {
            setEstimateDate('date_end', endOfDay(to))
          }
          setEstimateDate('date_start', from)
          if (from && to) {
            const estimate = formEstimate.get()
            if (!estimate.user_id || !estimate.estimate) {
              autosaveContext.resetLastValidEstimate({
                date_start: formatDate(from),
                date_end: formatDate(to),
              })
            }
            autosaveContext.markEstimateChanged()
            setTimeout(() => formik.validateForm())
          }
        }}
      />
    </DateFieldContainer>
  )
}
