import { formatEstimateTime } from 'helpers/formatEstimateTime'
import { useFormikMediator } from 'hooks/useFormikMediator'
import Input from 'ui/components/forms/Input'

export function TaskEstimateValue({ estimateIndex, autosaveContext }) {
  const formEstimate = useFormikMediator(`estimates[${estimateIndex}]`)

  return (
    <Input
      style={{ margin: 0 }}
      meta={formEstimate.getMeta('estimate')}
      {...formEstimate.getProps('estimate')}
      placeholder="Оценка *"
      onBlur={(event) => {
        const currentEstimateValue = autosaveContext.getLastEstimateValue()
        const newValue = event?.target?.value
        if (currentEstimateValue != newValue) {
          if (!formEstimate.get('user_id')) {
            autosaveContext.resetLastValidEstimate({
              estimate: formatEstimateTime(newValue),
            })
          }
          autosaveContext.markEstimateChanged()
          formEstimate.set('estimate', formatEstimateTime(newValue))
          autosaveContext.setLastEstimateValue(formEstimate.get('estimate'))
        }
      }}
    />
  )
}
