export function retrieveProjectSlug(router) {
  let projectSlug
  if (router) {
    projectSlug = router?.query?.projectSlug
  }
  if (!projectSlug && typeof window !== 'undefined') {
    projectSlug = window.location.pathname.match(
      /^\/projects\/(?<slug>.+?)($|\/)/
    )?.groups['slug']
  }
  return projectSlug
}
