import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUserList,
  getCurrentUser,
  createUser,
  updateUser,
  getUserById,
  getUserTasks,
  updateUserTags,
} from 'api/users/'
import { notifyError } from 'ui/components/Notify'

export const getCurrentUserAction = createAsyncThunk(
  'users/getCurrentUser',
  async () => {
    return await getCurrentUser()
  }
)

export const getUserByIdAction = createAsyncThunk(
  'users/getUserById',
  async (data) => {
    return await getUserById(data.id)
  }
)

export const getUserListAction = createAsyncThunk(
  'users/getList',
  async (statuses) => {
    return await getUserList(statuses)
  }
)

export const updateUserAction = createAsyncThunk(
  'users/update',
  async (data) => {
    return await updateUser(data.id, data.values)
  }
)

export const createUserAction = createAsyncThunk(
  'users/create',
  async (data) => {
    return await createUser(data)
  }
)

export const getUserTasksAction = createAsyncThunk(
  'users/getTask',
  async (data) => {
    if (typeof data === 'string') {
      return await getUserTasks(data)
    } else return await getUserTasks(data.userId, data, data.controller)
  }
)

export const updateUserTagsAction = createAsyncThunk(
  'users/updateUserTags',
  async (data) => {
    return await updateUserTags(data)
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    currentUser: null,
    getListStatus: 'idle',
    createStatus: '',
    updateStatus: '',
    getUserByIdStatus: '',
    user: null,
    list: [],
    fullViewMode: false,
    userTask: null,
    createdUser: null,
    getUserTaskStatus: 'idle',
    theme: null,
  },
  reducers: {
    clearUser: (state) => {
      state.user = null
    },
    clearCreateStatus: (state) => {
      state.createStatus = ''
    },
    clearUpdateStatus: (state) => {
      state.updateStatus = ''
    },
    toggleTheme: (state, action) => {
      state.theme = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserListAction.pending, (state) => {
        state.getListStatus = 'pending'
      })
      .addCase(getUserListAction.fulfilled, (state, action) => {
        state.list = action.payload.data || []
        state.getListStatus = 'idle'
      })
    builder.addCase(getCurrentUserAction.fulfilled, (state, action) => {
      state.currentUser = action.payload.data || null
    })
    builder
      .addCase(createUserAction.pending, (state) => {
        state.createStatus = 'pending'
      })
      .addCase(createUserAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.createStatus = 'error'
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message
          )
        } else {
          ;(state.createStatus = 'success'),
            (state.createdUser = action.payload?.data)
        }
      })
    builder
      .addCase(updateUserAction.pending, (state) => {
        state.updateStatus = 'pending'
      })
      .addCase(updateUserAction.fulfilled, (state) => {
        state.updateStatus = 'success'
      })
    builder
      .addCase(getUserByIdAction.pending, (state) => {
        state.getUserByIdStatus = 'pending'
      })
      .addCase(getUserByIdAction.fulfilled, (state, action) => {
        state.user = action.payload.data || null
        state.getUserByIdStatus = 'success'
      })
    builder
      .addCase(getUserTasksAction.pending, (state) => {
        state.getUserTaskStatus = 'pending'
      })
      .addCase(getUserTasksAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          state.getUserTaskStatus = 'error'
        } else {
          state.getUserTaskStatus = 'success'
          state.userTask = action.payload.data
          state.fullViewMode = !!action.meta.arg.full_view
        }
      })
  },
})

export const userListSelector = (state) => state.users.list
export const userListStatusSelector = (state) => state.users.getListStatus
export const createUserStatusSelector = (state) => state.users.createStatus
export const updateUserStatusSelector = (state) => state.users.updateStatus
export const currentUserSelector = (state) => state.users.currentUser
export const themeSelector = (state) => state.users.theme
export const userSelector = (state) => state.users.user
export const userTaskStatusSelector = (state) => state.users.getUserTaskStatus
export const userTaskSelector = (state) => state.users.userTask
export const userTasksFullViewModeSelector = (state) => state.users.fullViewMode
export const createdUserSelector = (state) => state.users.createdUser

export const { clearUser, clearCreateStatus, clearUpdateStatus, toggleTheme } =
  usersSlice.actions

export default usersSlice.reducer
