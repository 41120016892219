import { notifyError, notifySuccess } from 'ui/components/Notify'
import extractResponseError from './extractResponseError'

export async function safeQuery(fetcher, { successMessage } = {}) {
  try {
    const response = await fetcher()
    if (
      (!response && response !== '') ||
      response.status < 200 ||
      response.status >= 300
    ) {
      notifyError(extractResponseError(response))
    } else {
      if (successMessage) {
        notifySuccess(successMessage)
      }
      return response === '' ? true : response
    }
  } catch (e) {
    notifyError(extractResponseError(e.response ?? e))
  }
  return null
}
