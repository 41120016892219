import { Svg } from './styled'

const Icon = ({
  name,
  className,
  section = 'general',
  size = '16',
  viewBox,
}) => (
  <Svg className={className} size={size} {...({ viewBox } || {})}>
    <use xlinkHref={`/sprites/${section}.svg#${name}`} />
  </Svg>
)

export default Icon
