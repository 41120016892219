import { createSlice } from '@reduxjs/toolkit'
import {
  getComponentAction,
  getPriorityAction,
  getTaskTypeAction,
  getTaskStageAction,
  getFlowAction,
  getGenderListAction,
  getTagListAction,
  getEpicListAction,
  createTagAction,
  deleteTagAction,
  getProjectTypeAction,
} from './dictionaries.thunk'

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: {
    getComponentsStatus: 'idle',
    getPrioritiesStatus: 'idle',
    getTaskTypeStatus: 'idle',
    getTaskStageStatus: 'idle',
    getFlowStatus: 'epmty',
    getGendersStatus: 'idle',
    getTagListStatus: 'idle',
    getEpicListStatus: 'idle',
    createTagStatus: 'idle',
    deleteTagStatus: 'idle',
    projectTypeStatus: 'idle',
    projectType: [],
    components: [],
    priorities: [],
    taskTypes: [],
    taskStages: [],
    flow: [],
    genders: [],
    tags: [],
    epics: [],
    countPerPage: [
      { id: 1, name: 20 },
      { id: 2, name: 50 },
      { id: 3, name: 100 },
    ],
  },
  reducers: {
    clearCreateTagStatus: (state) => {
      state.createTagStatus = 'idle'
    },
    clearDeleteTagStatus: (state) => {
      state.deleteTagStatus = 'idle'
    },
    clearEpicList: (state) => {
      state.epics = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComponentAction.pending, (state) => {
        state.getComponentsStatus = 'pending'
      })
      .addCase(getComponentAction.fulfilled, (state, action) => {
        state.components = action.payload.data || []
        state.getComponentsStatus = 'idle'
      })
    builder
      .addCase(getPriorityAction.pending, (state) => {
        state.getPrioritiesStatus = 'pending'
      })
      .addCase(getPriorityAction.fulfilled, (state, action) => {
        state.priorities = action.payload.data || []
        state.getPrioritiesStatus = 'idle'
      })
    builder
      .addCase(getTaskTypeAction.pending, (state) => {
        state.getTaskTypeStatus = 'pending'
      })
      .addCase(getTaskTypeAction.fulfilled, (state, action) => {
        state.getTaskTypeStatus = 'idle'
        state.taskTypes = action.payload.data || []
      })
    builder
      .addCase(getTaskStageAction.pending, (state) => {
        state.getTaskStageStatus = 'pending'
      })
      .addCase(getTaskStageAction.fulfilled, (state, action) => {
        state.taskStages = action.payload.data || []
        state.getTaskStageStatus = 'idle'
      })
    builder
      .addCase(getFlowAction.pending, (state) => {
        state.getFlowStatus = 'epmty'
      })
      .addCase(getFlowAction.fulfilled, (state, action) => {
        state.getFlowStatus = 'success'
        state.flow = action.payload.data || []
      })
    builder
      .addCase(getGenderListAction.pending, (state) => {
        state.getGendersStatus = 'pending'
      })
      .addCase(getGenderListAction.fulfilled, (state, action) => {
        state.getGendersStatus = 'idle'
        state.genders = action.payload.data || []
      })
    builder
      .addCase(getTagListAction.pending, (state) => {
        state.getTagListStatus = 'pending'
      })
      .addCase(getTagListAction.fulfilled, (state, action) => {
        state.getTagListStatus = 'idle'
        state.tags = action.payload.data || []
      })
    builder
      .addCase(getEpicListAction.pending, (state) => {
        state.getEpicListStatus = 'pending'
      })
      .addCase(getEpicListAction.fulfilled, (state, action) => {
        state.getEpicListStatus = 'idle'
        state.epics = action.payload.data || []
      })
    builder
      .addCase(createTagAction.fulfilled, (state) => {
        state.createTagStatus = 'success'
      })
      .addCase(createTagAction.rejected, (state) => {
        state.createTagStatus = 'error'
      })
    builder.addCase(deleteTagAction.fulfilled, (state) => {
      state.deleteTagStatus = 'success'
    })
    builder
      .addCase(getProjectTypeAction.pending, (state) => {
        state.projectTypeStatus = 'pending'
      })
      .addCase(getProjectTypeAction.fulfilled, (state, action) => {
        state.projectType = action.payload.data || []
        state.projectTypeStatus = 'idle'
      })
  },
})

export const componentsSelector = (state) => state.dictionaries.components
export const prioritiesSelector = (state) => state.dictionaries.priorities
export const prioritiesStatusSelector = (state) =>
  state.dictionaries.getPrioritiesStatus
export const taskTypesSelector = (state) => state.dictionaries.taskTypes
export const taskTypesStatusSelector = (state) =>
  state.dictionaries.getTaskTypeStatus
export const taskStagesStatusSelector = (state) =>
  state.dictionaries.getTaskStageStatus
export const taskStagesSelector = (state) => state.dictionaries.taskStages
export const flowSelector = (state) => state.dictionaries.flow
export const flowStatusSelector = (state) => state.dictionaries.getFlowStatus
export const countPerPageSelector = (state) => state.dictionaries.countPerPage
export const genderListSelector = (state) => state.dictionaries.genders
export const tagListSelector = (state) => state.dictionaries.tags
export const epicListSelector = (state) => state.dictionaries.epics
export const createTagStatusSelector = (state) =>
  state.dictionaries.createTagStatus
export const deleteTagStatusSelector = (state) =>
  state.dictionaries.deleteTagStatus

export const projectTypeSelector = (state) => state.dictionaries.projectType

export const { clearCreateTagStatus, clearDeleteTagStatus, clearEpicList } =
  dictionariesSlice.actions

export const dictionariesReducer = dictionariesSlice.reducer
