import { api } from '../api'

export const dictionariesApi = api
  .enhanceEndpoints({
    addTagTypes: [
      'dictionaries',
      'projectType',
      'flow',
      'genders',
      'passboltFolder',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProjectType: builder.query({
        providesTags: ['projectType'],
        query: () => {
          return {
            url: `/project_type`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      getFlow: builder.query({
        providesTags: ['flow'],
        query: () => {
          return {
            url: `/flow`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      getGenders: builder.query({
        providesTags: ['genders'],
        query: () => {
          return {
            url: '/gender',
            method: 'get',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      getTags: builder.query({
        providesTags: ['tags'],
        query: () => {
          return {
            url: '/tag',
            method: 'get',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      getPassboltFolder: builder.query({
        providesTags: ['passboltFolder'],
        query: () => {
          return {
            url: '/passbolt/folder',
            method: 'get',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
    }),
  })

export const {
  useGetProjectTypeQuery,
  useGetFlowQuery,
  useGetTagsQuery,
  useGetGendersQuery,
  useGetPassboltFolderQuery,
} = dictionariesApi
