import classNames from 'classnames'
import InputMask from 'react-input-mask'
import Icon from 'ui/components/Icon'
import {
  InputWrap,
  InputLabel,
  InputInner,
  InputElement,
  InputError,
  IconContainer,
} from './styled'
import { forwardRef } from 'react'

const Input = forwardRef(
  (
    {
      label,
      upperLabel = true,
      style,
      required = false,
      half = false,
      meta,
      mask,
      type = 'text',
      maskPlaceholder = '_',
      maskChar = '',
      isClearable,
      onChange,
      value,
      className,
      ...otherProps
    },
    ref
  ) => {
    const isTouched = meta && meta.touched
    const hasError = isTouched && meta.error

    const clearValue = () => {
      const e = {
        target: {
          value: '',
        },
      }
      onChange(e)
    }

    return (
      <InputWrap
        style={style}
        half={half}
        className={classNames(
          {
            'has-error': hasError,
          },
          className
        )}
      >
        {label && upperLabel && (
          <InputLabel>
            {label} {required && <span>*</span>}
          </InputLabel>
        )}
        <InputInner>
          {mask ? (
            <InputMask
              mask={mask}
              maskPlaceholder={maskPlaceholder}
              maskChar={maskChar}
              value={value}
              onChange={onChange}
              placeholder={`${label}${required ? '*' : ''}`}
              {...otherProps}
            >
              <InputElement
                value={value}
                onChange={onChange}
                required={required}
                {...otherProps}
              />
            </InputMask>
          ) : (
            <InputElement
              value={value}
              onChange={onChange}
              type={type}
              required={required}
              placeholder={`${label}${required ? '*' : ''}`}
              {...otherProps}
            />
          )}
          {isClearable && value !== '' && (
            <IconContainer onClick={() => clearValue()}>
              <Icon name="close" />
            </IconContainer>
          )}
        </InputInner>

        {hasError && <InputError>{meta.error}</InputError>}
      </InputWrap>
    )
  }
)

export default Input
