import DatePicker from 'react-datepicker'
import styled from 'styled-components'

export const DatePickerStyled = styled(DatePicker)`
  .react-datepicker-wrapper:has(&)
    + *
    .react-datepicker__day--today.react-datepicker__day--selected:not(
      .react-datepicker__day--in-selecting-range
    ) {
    color: white;
  }
`
