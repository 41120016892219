import styled, { css } from 'styled-components'
import { PALETTE, BREAKPOINTS } from 'styles/constants'
import { Position } from 'ui/components/UserCardSmall/styled'

export const Table = styled.table`
  border: 1px solid #f4f6f8;

  @media (min-width: ${BREAKPOINTS.medium}) {
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    margin-bottom: 40px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: block;
    overflow: scroll;
    margin: 0;
  }
`

export const TableHead = styled.thead`
  &:first-child {
    color: #abbed1;
  }

  @media (min-width: ${BREAKPOINTS.medium}) {
    td {
      border-bottom: 1px solid ${PALETTE.paleGrey};
    }
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
  }
`

export const TableBody = styled.tbody`
  @media (min-width: ${BREAKPOINTS.medium}) {
    background: ${PALETTE.white};
    border-radius: 4px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: block;
  }
`

export const TableRow = styled.tr`
  position: relative;
  background-color: #fff;

  ${(p) =>
    p.sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}

  @media (min-width: ${BREAKPOINTS.medium}) {
    ${Position} {
      display: none;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    width: 100%;
    background: ${PALETTE.white};
  }
`

export const TableCell = styled.td`
  border: 1px solid #f4f6f8;
  white-space: pre-line;
  max-width: 200px;
  min-width: ${(p) => (p.minWidth ? p.minWidth : '80px')};
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : '200px')};
  line-height: 1.3;
  background: inherit;
  font-weight: ${(p) => (p.bold ? '600' : '500')};
  /* text-align: center; */

  ${(p) =>
    p.theme === 'light' &&
    css`
      color: ${PALETTE.grey};
      font-weight: 400;
    `}

  ${(p) =>
    p.align === 'right' &&
    css`
      text-align: right;
    `}

  ${(p) =>
    p.align === 'left' &&
    css`
      text-align: left;
    `}

  @media (min-width: ${BREAKPOINTS.medium}) {
    padding: 12px 16px;
    font-size: ${(p) => (p.dense ? '12px' : '14px')};

    ${(p) =>
      p.paddingLeft &&
      css`
        padding-left: ${p.paddingLeft};
      `}
    ${(p) =>
      p.cell === 'dropdown' &&
      css`
        & > div {
          margin-left: auto;
        }
      `}
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: block;
    max-width: ${(p) => (p.width ? `${p.width}px` : '100px')};
    min-width: ${(p) => (p.width ? `${p.width}px` : '100px')};
    padding: 5px;
    font-size: 12px;
    ${(p) =>
      p.cell === 'user' &&
      css`
        margin-bottom: 20px;
        padding-right: 25px;
        @media (max-width: ${BREAKPOINTS.mobile}) {
          margin: 0;
        }
      `}
    ${(p) =>
      p.cell === 'position' &&
      css`
        margin: 5px 0;
      `}
    ${(p) =>
      p.cell === 'dropdown' &&
      css`
        min-width: 0;
        position: static;
      `}
    ${(p) =>
      p.cell === 'base' &&
      css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
      `}
  }

  ${(p) =>
    p.fullWidthMobile &&
    css`
      @media (max-width: ${BREAKPOINTS.mobile}) {
        max-width: 100%;
        min-width: 0;
        width: 100%;
      }
    `}
`

export const TableCellLabel = styled.div`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${PALETTE.grey};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`
